import React from 'react';

import "./Tag.scss";



const Tag = ({ name, className, icon, langSource }) => {

    const source = langSource.departmenttags;

    return (
        <div className={`tag ${className}`}>
            <img src={icon} alt={name} />
            <span>{source[name]}</span>
        </div>
    )
}

export default Tag;