import React from 'react';


import Tag from "./Tag/Tag";


import iconFinance from '../../../../img/icons/tags/icon-tag-finance.svg';
import iconMkt from '../../../../img/icons/tags/icon-tag-marketing.svg';
import iconBusiness from '../../../../img/icons/tags/icon-tag-business.svg';
import iconProduct from '../../../../img/icons/tags/icon-tag-product.svg';
import iconOperations from '../../../../img/icons/tags/icon-tag-operations.svg';
import iconTech from '../../../../img/icons/tags/icon-tag-tech.svg';
import iconPeople from '../../../../img/icons/tags/icon-tag-people.svg';


import "./TagGrid.scss";


const TagGrid = ({ langSource }) => {

    const tags = [
        {
            name: "finance",
            className: "blue",
            icon: iconFinance
        },
        {
            name: "marketing",
            className: "yellow",
            icon: iconMkt
        },
        {
            name: "newbusiness",
            className: "coral",
            icon: iconBusiness
        },
        {
            name: "product",
            className: "orange",
            icon: iconProduct
        },
        {
            name: "operations",
            className: "lime",
            icon: iconOperations
        },
        {
            name: "tech",
            className: "red",
            icon: iconTech
        },
        {
            name: "people",
            className: "purple",
            icon: iconPeople
        }
    ]

    const renderTags = () => {
        return tags.map((tag, index) => {
            return (
                    <li key={index}>
                        <Tag langSource={langSource}
                             className={tag.className}
                             name={tag.name}
                             title={tag.name}
                             icon={tag.icon}
                        />
                    </li>
           )

        });
    }

    return (
        <ul className="tag-grid">
            {renderTags()}
        </ul>
    )
}


export default TagGrid;
