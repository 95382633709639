import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


import Header from "./components/layout/Header/Header";
import Footer from "./components/layout/Footer/Footer";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import AboutUs from "./pages/AboutUs/AboutUs";
import LifeAtTMH from "./pages/LifeAtTMH/LifeAtTMH";
import LegalPage from "./pages/LegalPage/LegalPage";

import ScrollToTop from "./components/ScrollToTop";

import es from '../lang/es.json';
import "../scss/reset.scss";


const App = () => {

    const [language, setLanguage] = useState(es);

    useEffect(() => {
        renderContent();
        // eslint-disable-next-line
    }, [language]);



    const renderContent = () => {

        return (
            <BrowserRouter>
                    <div>

                        <ScrollToTop />
                        <Header setLanguage={(lang) => setLanguage(lang)} langSource={language} />

                        <Switch>
                            <Route path="/" exact render={() => ( <Home langSource={language} />)} />
                            <Route path="/about-us" render={() => ( <AboutUs langSource={language} />)} />
                            <Route path="/contact" render={() => ( <Contact langSource={language} />)} />
                            <Route path="/life-at-tmh" render={() => ( <LifeAtTMH langSource={language} />)} />
                            <Route
                                path="/privacy-policy"
                                render={() => (<LegalPage langSource={language} pageType="privacy-policy"/>)}
                            />
                            <Route
                                path="/terms"
                                render={() => ( <LegalPage langSource={language} pageType="terms"/>)}
                            />
                        </Switch>

                        <Footer setLanguage={(lang) => setLanguage(lang)} langSource={language} />
                    </div>

            </BrowserRouter>
        )
    }

    return (
        renderContent()
    )

}

export default App;