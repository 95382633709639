import React from 'react';

import "./Country.scss";

const Country = ({ name, year, flag, title, href }) => {


    const renderYear = () => {
        if (year) {
            return (
                <span>{year}</span>
            )
        }
    }

    return (
        <div className="country">
            {renderYear()}
            <img src={flag} alt={title} />

            <div>
                <p>{name}</p>
                <a href={href} title={title} target="_blank" rel="noreferrer">{title}</a>
            </div>
        </div>
    )
}

export default Country;