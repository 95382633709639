import React from 'react';
import ReactMarkdown from "react-markdown";

import Headquarter from "./Headquarter/Headquarter";
import RecruiterCard from "./RecruiterCard/RecruiterCard";
import JoinUs from "../../components/blocks/JoinUs/JoinUs";
import Entry from "../../components/atoms/Entry/Entry";

import claudia from "../../../img/contact/recruiters/claudia-min.png";
import karen from "../../../img/contact/recruiters/karen-min.png";

import "./Contact.scss";


const Contact = ({ langSource }) => {

    const source = langSource.contactpage;

    return (
        <div className="contact">

            <div className="contact-header">
                <Entry content={source["entry"]} className="green" />
                <ReactMarkdown source={source["title"]} className="title"/>
                <p className="subtitle">{source["subtitle"]}</p>
            </div>

            <div className="contact-gallery">
                <Headquarter
                    className="headquarter--main-office"
                    title="Sant Cugat del Vallés HQ"
                    map="santcugat"
                    address={["Sant Cugat Trade Center","Av. Corts Catalanes, 5-7 (Oficina A-04)","Sant Cugat del Vallès, Cataluña 08174, ES"]}
                />

                <Headquarter
                    className="headquarter--second-office"
                    title="Barcelona HQ"
                    map="barcelona"
                    address={["Carrer d'aragó, 264, Ático 1 y 2","Barcelona, Cataluña 08007, ES"]}
                />
            </div>


            <section className="contact-recruiters">

                <div className="contact-recruiters__header">
                    <ReactMarkdown source={source["recruiterstitle"]} className="title"/>
                    <p className="subtitle">{source["recruiterssubtitle"]}</p>
                </div>

                <div className="contact-recruiters__wrapper">
                    <RecruiterCard
                        name="Claudia"
                        img={claudia}
                        job="Tech recruiter"
                        quote={source["claudiaquote"]}
                        linkedin="https://www.linkedin.com/in/claudia-coy-p%C3%A9rez-b73455151/"
                        email="ccoy@intercomempresas.com"
                        phone="620 637 113"
                    />

                    <RecruiterCard
                        name="Karen"
                        img={karen}
                        job="Business recruiter"
                        quote={source["karenquote"]}
                        linkedin="https://www.linkedin.com/in/karen-ozerans"
                        email="kozerans@intercomempresas.com"
                        phone="616 642 762"
                    />
                </div>

            </section>

            <JoinUs className="dark" langSource={langSource} />
        </div>
    )
}

export default Contact;
