import React from 'react';
import ReactMarkdown from "react-markdown";

import Entry from "../../atoms/Entry/Entry";

import glassdoorIcon from '../../../../img/blocks/stats/glassdoor-icon.svg';
import nationalitiesIcon from '../../../../img/blocks/stats/nationalities-icon.svg';
import membersIcon from '../../../../img/blocks/stats/members-icon.svg';
import slackIcon from '../../../../img/blocks/stats/slack-icon.svg';


import "./Stats.scss";


const Stats = ({ langSource }) => {

    const source = langSource.stats;

    return (
        <section className="stats">

            <div className="stats-content wrapper">

                <div className="stats-heading">
                    <Entry className="blue" content={source["entry"]} />
                    <ReactMarkdown source={source["title"]} />
                </div>


                <div className="stats-grid">
                    <a
                        href="https://www.glassdoor.es/Resumen/Trabajar-en-The-Mind-Hub-Company-EI_IE3854299.12,32.htm"
                        className="stats-grid__item glassdoor"
                        target="_blank"
                        rel="noreferrer">
                        <img src={glassdoorIcon} alt="5 glassdoor" />
                    </a>

                    <div className="stats-grid__item nationalities">
                        <img src={nationalitiesIcon} alt={`8 ${source["title"]}`} />
                        <span className="num">8</span>
                        <p>{source["nationalities"]}</p>
                    </div>

                    <div className="stats-grid__item members">

                        <div className="flex">
                            <div>
                                <img src={membersIcon} alt={`+90 ${source["members"]}`} />
                                <p className="intro">+</p>
                            </div>
                            <span className="num">90</span>
                        </div>

                        <p>{source["members"]}</p>
                    </div>

                    <div className="stats-grid__item slack flex">
                        <div>
                            <img src={slackIcon} alt={source["gifs"]} />
                            <p className="intro">{source["morethan"]}</p>
                        </div>

                        <div>
                            <span className="num">100</span>
                            <p>{source["dailygifs"]}</p>
                        </div>
                    </div>
                </div>

                <ReactMarkdown source={source["ending"]} className="stats-ending"/>
            </div>

        </section>
    )
}


export default Stats;