import React from 'react';
import ReactMarkdown from "react-markdown";

import Entry from "../../../components/atoms/Entry/Entry";
import Anchor from "../../../components/atoms/Anchor/Anchor";

import "./HomeSection.scss";

const HomeSection = ({ className, img, title, entry, paragraph, color, anchorType, anchorHref, langSource, children }) => {

    const source = langSource.home;

    const renderAnchor = () => {
        if (anchorHref) {
            return (
                <Anchor
                    type={anchorType}
                    className={color}
                    content={source["section-cta"]}
                    title={source["section-cta"]}
                    href={anchorHref}
                    icon={true}
                />
            )
        }
    }

    return (
        <div className={`home-section ${className}`}>
            <img src={img} className="section-icon" alt={title} />
            <Entry content={entry} className={color} />

            <ReactMarkdown
                source={title}
                className="title">
                {source}
            </ReactMarkdown>

            <p className="paragraph">{paragraph}</p>

            {renderAnchor()}
            {children}
        </div>
    )
}

export default HomeSection;