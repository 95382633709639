import React from 'react';
import GoogleMap from "../../../components/GoogleMap";

import "./Headquarter.scss";


const Headquarter = ({ title, address, className, map }) => {

    let lat;
    let lon;

    const renderMap = () => {
        if (map === "santcugat") {
            //41.47512778815085, 2.097831269548141

            lat = 41.47512778815085;
            lon = 2.0978309999999993;
        }

        if (map === "barcelona") {
            lat = 41.47498933558355;
            lon = 2.1616976;
        }
    }

    renderMap();

    return (
        <div className={`headquarter ${className}`}>
            <div className="headquarter-title">
                <p>{title}</p>
            </div>

            <picture/>

            <GoogleMap lon={lon} lat={lat} address={address} className="headquarter-map" />

            <div className="headquarter-label">
                {address.map((item) => <p>{item}</p>)}
            </div>
            <span className="headquarter-badge" />
        </div>
    )
}

export default Headquarter;
