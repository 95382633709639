import React from 'react';




import iconLocation from '../../../../../img/icons/icon-location.svg';
import iconArrowOrange from '../../../../../img/icons/arrows/icon-arrow-large-orange.svg';
import iconArrowRed from '../../../../../img/icons/arrows/icon-arrow-large-red.svg';
import iconArrowGreen from '../../../../../img/icons/arrows/icon-arrow-large-green.svg';



import "./DepartmentCard.scss";



const CareerCard = ({ name, href, info, location, className, langSource }) => {

    const source = langSource.careers;

    const renderArrowIcon = () => {
        if (className === 'orange') {
            return (
                <img className="department-card-arrow-icon" src={iconArrowOrange} alt="" />
            )
        }

        if (className === 'red') {
            return (
                <img className="department-card-arrow-icon" src={iconArrowRed} alt="" />
            )
        }

        if (className === 'green') {
            return (
                <img className="department-card-arrow-icon" src={iconArrowGreen} alt="" />
            )
        }
    }


    return (
        <a href={href} className={`department-card ${className}`} target="_blank" rel="noreferrer">
            <h3 className="title">{source[name]}</h3>
            <p>{source[info]}</p>

            <div className="department-card-location">
                <img className="department-card-location-icon"
                     src={iconLocation}
                     alt={location}
                />
                <span>{location}</span>

                {renderArrowIcon()}
            </div>
        </a>
    )
}

export default CareerCard;
