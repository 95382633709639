import React from 'react';

import Hero from "./Hero/Hero";
import HomeSection from "./HomeSection/HomeSection";
import Stats from "../../components/blocks/Stats/Stats";
import TagGrid from "./TagGrid/TagGrid";
import Perks from "../../components/blocks/Perks/Perks";
import Careers from "./Careers/Careers";

import iconPhoto from '../../../img/icons/icon-photo.svg';
import iconScreen from '../../../img/icons/icon-screen.svg';
import iconEarth from '../../../img/icons/icon-earth.svg';
import iconAtom from '../../../img/icons/icon-atom.svg';

import laptopImg from '../../../img/home/laptop-min.png';
import laptopWebp from '../../../img/home/laptop.webp';
import mindsImg from '../../../img/home/minds-min.png';
import mindsWebp from '../../../img/home/minds.webp';

import "./Home.scss";


class Home extends React.Component {

    state = {
        fromHash: null
    }

    constructor(props) {
        super(props);
        this._handleScrollToTarget = this._handleScrollToTarget.bind(this);
    }

    componentDidMount() {
        if (window.location.state) {
            this._handleScrollToTarget();
        }
    }

    _handleScrollToTarget() {
        let id = this.props.location.state.fromHash;
        let scrollTarget = document.getElementById(id);

        setTimeout(() => {
            scrollTarget.scrollIntoView({
                behavior: 'smooth'
            })
        }, 800);
    }

    render() {

        const source = this.props.langSource.home;

        return (
            <div id="home" className="home">

                <Hero langSource={this.props.langSource} />

                <section>
                    <div className="wrapper">
                        <HomeSection
                            langSource={this.props.langSource}
                            className="home-section--about-us"
                            color="orange"
                            img={iconPhoto}
                            title={source["about-section-title"]}
                            entry={source["about-section-entry"]}
                            paragraph={source["about-section-paragraph"]}
                            anchorType="link"
                            anchorHref="/about-us"
                        />

                        <div className="home-picture">
                            <source srcSet={laptopWebp} type="image/webp" />
                            <img src={laptopImg} alt="" />
                        </div>
                    </div>
                </section>

                <Stats langSource={this.props.langSource}/>

                <section id="our-minds">
                    <div className="wrapper">
                        <HomeSection
                            langSource={this.props.langSource}
                            className="home-section--our-minds"
                            color="yellow"
                            img={iconScreen}
                            title={source["ourminds-section-title"]}
                            entry={source["ourminds-section-entry"]}
                            paragraph={source["ourminds-section-paragraph"]}
                        >
                            <TagGrid langSource={this.props.langSource} />
                        </HomeSection>

                        <picture className="home-picture">
                            <source srcSet={mindsWebp} type="image/webp" />
                            <img src={mindsImg} alt="" />
                        </picture>
                    </div>
                </section>

                <section>
                    <HomeSection
                        langSource={this.props.langSource}
                        className="home-section--life-at-tmh"
                        color="green"
                        img={iconEarth}
                        title={source["lifeattmh-section-title"]}
                        entry={source["lifeattmh-section-entry"]}
                        paragraph={source["lifeattmh-section-paragraph"]}
                        anchorType="link"
                        anchorHref="/life-at-tmh"
                    />
                </section>

                <Perks langSource={this.props.langSource}/>

                <section className="home-gallery">
                    <div className="wrapper">
                        <div className="home-gallery__img" />
                        <div className="home-gallery__img" />
                    </div>
                </section>


                <section id="careers">
                    <HomeSection
                        langSource={this.props.langSource}
                        className="home-section--careers"
                        color="blue"
                        img={iconAtom}
                        title={source["careers-section-title"]}
                        entry={source["careers-section-entry"]}
                        paragraph={source["careers-section-paragraph"]}
                        anchorType="anchor"
                        anchorHref="https://jobs.themindhubcompany.com/jobs"
                    />
                </section>

                <Careers id="careers" langSource={this.props.langSource} />
            </div>
        )
    }

}


export default Home;