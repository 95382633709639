import React from 'react';
import { Link } from 'react-router-dom';

import LangSelector from "../../LangSelector/LangSelector";
import Sitemap from "./Sitemap/Sitemap";

import linkedinLogo from '../../../../img/rrss/linked-in-full-logo.svg';
import babyFriendlyLogo from '../../../../img/footer/baby-friendly-logo.svg';
import gp2workLogo from '../../../../img/footer/gptw_certified_badge_ESP.svg';
import babyFriendlyPdf from "../../../../docs/diploma-baby-friendly.pdf";

import "./Footer.scss";

const Footer = ({ setLanguage, langSource }) => {

    const source = langSource.footer;

    return (
        <footer className="footer">

            <Sitemap langSource={langSource} />

            <LangSelector
                onClick={(lang) => setLanguage(lang)}
                flagOrText="flag"
            />
            
            <div className="footer-logos">
                <div className="baby-friendly">
                    <a href={babyFriendlyPdf} target="_blank" rel="noreferrer">
                        <img width="100" height="70" src={babyFriendlyLogo} alt="Baby Friendly logo" />
                    </a>
                </div>
                <div className="GPTW">
                    <a href="https://greatplacetowork.es/" target="_blank" rel="nofollow" title="Great Place to Work España">
                        <img src={gp2workLogo} alt="GPTW logo" />
                    </a>
                </div>
            </div>


            <div className="footer-info">

                <p className="linkedin">
                    <span>Learn more at</span>
                    <a href="https://www.linkedin.com/company/the-mind-hub-company/" target="_blank" rel="noreferrer">
                        <img src={linkedinLogo} alt="Learn more at" />
                    </a>
                </p>

                <div className="legal">
                    <p>{source["legal"]}</p>

                    <div className="legal-links">
                        <Link
                            to="/privacy-policy"
                            href="/"
                            title={source["privacypolicy"]}>
                            {source["privacypolicy"]}
                        </Link>

                        <span>·</span>
                        <Link
                            to="/terms"
                            href="/"
                            title={source["terms"]}>
                            {source["terms"]}
                        </Link>

                        <span>·</span>
                        <a href="https://tmh.bizneohr.com/whistleblowing-channel" title={source["complaintschannel"]} target="_blank" rel="noreferrer">{source["complaintschannel"]}</a>
                    </div>
                </div>



            </div>

        </footer>
    )
}

export default Footer;