import React from 'react';

import iconLinkedin from "../../../../img/rrss/linkedin-fill.svg";
import iconEmail from "../../../../img/icons/icon-email-fill.svg";
import iconPhone from "../../../../img/icons/icon-phone.svg";

import "./RecruiterCard.scss";

const RecruiterCard = ({ name, img, job, quote, linkedin, email, phone }) => {

    return (
        <div className="recruiter-card">
           <div className="recruiter-card-wrapper">
               <img className="recruiter-card-img" src={img} alt={name} />
               <p>{name}</p>
               <p>{job}</p>

               <div className="recruiter-card-contact">
                   <a href={`tel:+${phone}`} target="_blank" rel="noreferrer">
                       <img src={iconPhone} alt={phone} />
                   </a>

                   <a href={`mailto:${email}?subject=Hey ${name}!`} target="_blank" rel="noreferrer">
                       <img src={iconEmail} alt={email} />
                   </a>

                   <a href={linkedin} target="_blank" rel="noreferrer">
                       <img src={iconLinkedin} alt={linkedin} />
                   </a>
               </div>
           </div>

            <p className="recruiter-card-quote">{quote}</p>
        </div>
    )
}

export default RecruiterCard;