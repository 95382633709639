import React from 'react';


import iconInsurance from '../../../../img/blocks/perks/icon-perk-insurance.svg';
import iconRemote from '../../../../img/blocks/perks/icon-perk-remote.svg';
import iconDiscounts from '../../../../img/blocks/perks/icon-perk-discounts.svg';
import iconFlexible from '../../../../img/blocks/perks/icon-perk-flexible.svg';
import iconTraining from '../../../../img/blocks/perks/icon-perk-training.svg';
import iconFriday from '../../../../img/blocks/perks/icon-perk-friday.svg';


import "./Perks.scss";


const Perks = ({ langSource }) => {

    const source = langSource.perks;

    const perks = [
        {
            icon: iconInsurance,
            title: "insurance-title",
            info: "insurance-info"
        },
        {
            icon: iconRemote,
            title: "remote-title",
            info: "remote-info"
        },
        {
            icon: iconDiscounts,
            title: "discounts-title",
            info: "discounts-info"
        },
        {
            icon: iconFlexible,
            title: "flexible-title",
            info: "flexible-info"
        },
        {
            icon: iconTraining,
            title: "training-title",
            info: "training-info"
        },
        {
            icon: iconFriday,
            title: "fridays-title",
            info: "fridays-info"
        }
    ]

    const renderedPerks = () => {
        return perks.map((perk, index) => {
           return (
               <li key={index}>
                   <img src={perk.icon} alt={source[perk.title]} />
                   <p className="title">{source[perk.title]}</p>
                   <p className="info">{source[perk.info]}</p>
               </li>
           )
        });
    }



    return (
        <section className="perks">
            <ul>
                {renderedPerks()}
            </ul>
        </section>
    )
}

export default Perks;