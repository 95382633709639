import React from 'react';
import { Link } from "react-router-dom";

import logo from "../../../../../img/logo/logo.svg";

import "./Sitemap.scss";

const Sitemap = ({ langSource }) => {

    const source = langSource.navigationtabs;

    const links = [
        {
            href: "/about-us",
            title: "about-us"
        },
        {
            href: "/",
            title: "our-minds",
            hash: "#our-minds"
        },
        {
            href: "/life-at-tmh",
            title: "life-at-tmh"
        },
        {
            href: "/",
            title: "careers",
            hash: "#careers"
        },
        {
            href: "/contact",
            title: "contact"
        }
    ]

    const onSectionScroll = (section) => {

        setTimeout(() => {
            if (document.getElementById('home')) {
                let scrollTarget = document.getElementById(section);
                scrollTarget.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        }, 500);
    }

    const renderSitemapLinks = () => {
        return links.map((link, index) => {

            if (link.hash) {
                return (
                    <li key={index} onClick={() => onSectionScroll(link.title)}>
                        <Link to={link.href} title={source[link.title]}>
                            {source[link.title]}
                        </Link>
                    </li>
                )
            }

            return (
                <li key={index}>
                    <Link to={link.href} title={source[link.title]}>
                        {source[link.title]}
                    </Link>
                </li>
            )
        })
    }

    return (
        <ul className="sitemap">
            <img className="logo" src={logo} alt="The Mind Hub" />
            {renderSitemapLinks()}
        </ul>
    )
}

export default Sitemap;