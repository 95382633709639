import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import iconHome from '../../../../../../img/icons/tabs/icon-tab-home.svg';
import iconAbout from '../../../../../../img/icons/tabs/icon-tab-about-us.svg';
import iconOurMinds from '../../../../../../img/icons/tabs/icon-tab-our-minds.svg';
import iconLifeAtTMH from '../../../../../../img/icons/tabs/icon-tab-life-at-tmh.svg';
import iconCareers from '../../../../../../img/icons/tabs/icon-tab-careers.svg';
import iconContact from '../../../../../../img/icons/tabs/icon-tab-contact.svg';


import "./NavigationTabs.scss";

const NavigationTabs = ({ onTabClick, langSource }) => {

    const source = langSource.navigationtabs;

    const tabs = [
        {
            href: "/",
            title: "home",
            img: iconHome
        },
        {
            href: "/about-us",
            title: "about-us",
            img: iconAbout
        },
        {
            href: "/",
            title: "our-minds",
            img: iconOurMinds,
            hash: "#our-minds"
        },
        {
            href: "/life-at-tmh",
            title: "life-at-tmh",
            img: iconLifeAtTMH
        },
        {
            href: "/",
            title: "careers",
            img: iconCareers,
            hash: "#careers"
        },
        {
            href: "/contact",
            title: "contact",
            img: iconContact
        }
    ];


    const onSectionScroll = (section) => {

        setTimeout(() => {
            if (document.getElementById('home')) {
                let scrollTarget = document.getElementById(section);
                scrollTarget.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        }, 500);

        // Close navigation
        onTabClick();
    }

    const renderedTabs = () => {

        return tabs.map((tab, index) => {

            if (tab.hash) {
                return (
                    <li key={index} onClick={() => onSectionScroll(tab.title)}>
                        <Link to={{
                            pathname: tab.href,
                            hash: tab.hash,
                            state: {
                                fromHash: tab.title
                            }
                        }}>
                            <div><img src={tab.img} alt={tab.title} /></div>
                            <p>{source[tab.title]}</p>
                            <span></span>
                        </Link>
                    </li>
                )


            } else {
                return (
                    <li key={index} onClick={() => onTabClick()}>
                        <NavLink
                            exact
                            to={tab.href}
                            href={tab.href}
                            title={tab.title}
                            activeClassName="active"
                        >
                            <div><img src={tab.img} alt={tab.title} /></div>
                            <p>{source[tab.title]}</p>
                            <span></span>
                        </NavLink>
                    </li>
                )
            }

        })

    }

    return (
        <div className="navigation-tabs">
            <p className="navigation-title">menu</p>
            <ul>
                {renderedTabs()}
            </ul>
        </div>
    )
}

export default NavigationTabs;