import React from 'react';
import ReactPlayer from 'react-player';

import "./Video.scss";

class Video extends React.Component {

    render() {
        return (
            <div className="video">

                <div className="video-wrapper">
                    <ReactPlayer
                        url="https://player.vimeo.com/video/582886142?h=e34e0f0056"
                        loop="1"
                        autoplay="1"
                        width="100%" height="100%"
                        playing
                        style={{
                            transform: "skewY(-1deg)",
                            margin: "0 auto"
                        }}
                        controls={false} />
                </div>

            </div>
        )
    }
}

export default Video;
