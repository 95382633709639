import React, { Component } from 'react';

class GoogleMap extends Component {

    componentDidMount() {
        const google = window.google;
        const latLng = new google.maps.LatLng(this.props.lat, this.props.lon);
        const map = new google.maps.Map(this.refs.map, {
            zoom: 15,
            zoomControl: true,
            center: {
                lat: this.props.lat,
                lng: this.props.lon
            }
        });
        const marker = new google.maps.Marker({
            position: latLng,
            title: this.props.address,
            visible: true
        });
        marker.setMap(map);
    }

    render() {
        return <div ref="map" className={this.props.className} />;
    }
}

export default GoogleMap;