import React from 'react';
import ReactMarkdown from "react-markdown";

import Button from "../../atoms/Button/Button";

import "./JoinUs.scss";


const JoinUs = ({ className, langSource }) => {

    let buttonClass = '';
    const source = langSource.joinus;

    if (className === "dark") {
        buttonClass = "light";
    }

    if (className === "light") {
        buttonClass = "dark";
    }


    return (
        <div className={`join-us ${className}`}>

            <div>
                <ReactMarkdown source={source["title"]}/>
                <ReactMarkdown source={source["subtitle"]}/>

                <Button
                    content={source["cta"]}
                    className={buttonClass}
                    href="https://jobs.themindhubcompany.com/jobs"
                />
            </div>

        </div>
    )
}

export default JoinUs;