import React from 'react';

import "./Button.scss";

const Button = ({ className, content, href }) => {

    if (href) {
        return (
            <a
                className={`button ${className}`}
                href={href} target="_blank"
                rel="noreferrer">
                {content}
            </a>
        )
    }

    return (
        <button className={`button ${className}`}>{content}</button>
    )
}


export default Button;