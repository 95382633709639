import React from 'react';
import ReactMarkdown from "react-markdown";


import "./Hero.scss";

const Hero = ({ langSource }) => {

    const source = langSource.hero;

    const handleScrollToCareers = () => {
        let scrollTarget = document.getElementById("careers");

        scrollTarget.scrollIntoView({
            behavior: 'smooth'
        })
    }

    return (
        <div className="hero">

            <div className="wrapper">
                <div className="hero-text">
                    <ReactMarkdown source={source["title"]}/>
                    <ReactMarkdown source={source["subtitle"]} />
                    <button className="button white" onClick={() => handleScrollToCareers()}>{source["cta"]}</button>
                </div>
            </div>

        </div>
    )
}


export default Hero;