import React from 'react';
import { Link } from 'react-router-dom';

import LangSelector from "../../../LangSelector/LangSelector";
import NavigationTabs from "./NavigationTabs/NavigationTabs";
import NavigationFooter from "./NavigationFooter/NavigationFooter";

import logo from '../../../../../img/logo/logo.svg';
import iconClose from '../../../../../img/icons/icon-close.svg';


import "./Navigation.scss";


const Navigation = ({ onDisplay, className, setLanguage, langSource }) => {

    return (
        <div className={`navigation ${className}`}>

            <div className="navigation-shadow" onClick={() => onDisplay('hide')} />

            <div className="navigation-header">
                <Link to="/" href="/" title="The Mind Hub">
                    <img src={logo} alt="The Mind Hub"/>
                </Link>

                <img
                    alt="close menu"
                    className="close-button"
                    onClick={() => onDisplay('hide')}
                    src={iconClose}
                />
            </div>


            <div className="navigation-content">
                <LangSelector
                    flagOrText="text"
                    onClick={(lang) => setLanguage(lang)}
                />

                <LangSelector
                    flagOrText="flag"
                    onClick={(lang) => setLanguage(lang)}
                />

                <NavigationTabs onTabClick={() => onDisplay('hide')}  langSource={langSource} />
                <NavigationFooter langSource={langSource} />
            </div>

        </div>
    )

}

export default Navigation;