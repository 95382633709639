import React from 'react';
import ReactMarkdown from 'react-markdown';

import "./LegalPage.scss";

const Terms = ({ langSource, pageType }) => {

    let source;
    if (pageType === 'terms') {
        source = langSource.terms;
    }

    if (pageType === 'privacy-policy') {
        source = langSource.privacypolicy;
    }

    return (
        <section className="legal-page">

            <div className="legal-page-wrapper">
                <ReactMarkdown source={source}/>
            </div>

        </section>
    )
}

export default Terms;