import React from 'react';


import flagES from '../../../img/flags/icon-flag-es.svg';
import flagEN from '../../../img/flags/icon-flag-gb.svg';

import es from '../../../lang/es.json';
import en from '../../../lang/en.json';

import "./LangSelector.scss";


const LangSelector = ({ onClick, flagOrText }) => {

    const renderFlagOrText = (value, lang, src) => {

        let cssClasses = 'lang-selector__item';
        if (lang === 'es') {
            cssClasses = 'lang-selector__item selected'
        }

        if (value === 'flag') {
            return (
                <img
                    alt={lang}
                    src={src} data-lang={lang}
                    className={cssClasses}
                    onClick={(e) => handleClick(e, lang)}>
                </img>
            )
        }

        if (value === 'text') {
            return (
                <p
                    className={cssClasses} data-lang={lang}
                    onClick={(e) => handleClick(e, lang)}>
                    {lang}
                </p>
            )
        }
    }

    const handleClick = (e, value) => {
        const items = document.querySelectorAll('.lang-selector__item');
        let currentLang = e.target.getAttribute('data-lang');

        items.forEach((item) => {
            if (item.getAttribute('data-lang') === currentLang) {
                item.classList.add('selected');
            } else {
                item.classList.remove('selected');
            }
        });

        if (value === 'es') {
            onClick(es);
        }

        if (value === 'en') {
            onClick(en);
        }
    }



    return (
        <div className={`lang-selector ${flagOrText}`}>
            {renderFlagOrText(flagOrText, 'es', flagES)}
            {renderFlagOrText(flagOrText, 'en', flagEN)}
        </div>
    )
}

export default LangSelector;