import React from 'react';

import Stats from "../../components/blocks/Stats/Stats";
import JoinUs from "../../components/blocks/JoinUs/JoinUs";
import Timeline from "./Timeline/Timeline";

import Entry from "../../components/atoms/Entry/Entry";


import "./AboutUs.scss";
import ReactMarkdown from "react-markdown";

const AboutUs = ({ langSource }) => {

    const source = langSource.aboutuspage;

    return (
        <div className="about">

            <div className="about-header wrapper">
                <Entry className="orange" content={source["entry"]} />
                <h3 className="title">{source["title"]}</h3>
                <p className="subtitle">{source["subtitle"]}</p>
                <p className="paragraph">{source["paragraph-1"]}</p>
                <p className="paragraph">{source["paragraph-2"]}</p>
                <ReactMarkdown source={source["paragraph-3"]} className="paragraph" />
            </div>

            <Timeline langSource={langSource} />

            <section className="about-gallery-1">
                <span/>
                <picture/>
            </section>

            <div className="about-body wrapper">
                <ReactMarkdown source={source["paragraph-4"]} className="paragraph" />
            </div>

            <section className="about-gallery-2">
                <span/>
                <picture/>
            </section>

            <Stats langSource={langSource} />
            <JoinUs className="light" langSource={langSource} />
        </div>
    )
}

export default AboutUs;