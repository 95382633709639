import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Navigation from "./Navigation/Navigation";

import logo from '../../../../img/logo/logo.svg';
import gp2workLogo from '../../../../img/footer/gptw_certified_badge_ESP.svg';
import iconClose from "../../../../img/icons/icon-close.svg";

import "./Header.scss";


const Header = ({ setLanguage, langSource }) => {

    const [onNavDisplay, setOnNavDisplay] = useState('');

    const handleOnNavDisplay = (showOrHideClass) => {
        setOnNavDisplay(showOrHideClass);
        document.body.classList.toggle('fixed');
        document.querySelector('.hamburguer').classList.toggle('hide');
        document.querySelector('.close-button').classList.toggle('hide');
    }

    return (
        <header className="Header">

            <div className="wrapper">

                <Link to="/" href="/" className="Header-logo" title="The Mind Hub">
                    <img src={logo} alt="The Mind Hub" />
                </Link>

                <div className="GPTW">
                    <a href="https://greatplacetowork.es/" target="_blank" rel="nofollow" title="Great Place to Work España">
                        <img src={gp2workLogo} alt="GPTW logo" />
                    </a>
                </div>

                <div className="hamburguer" onClick={() => handleOnNavDisplay('show')}>
                    <span />
                    <span />
                    <span />
                </div>

                <img
                    alt="close menu"
                    className="close-button hide"
                    src={iconClose}
                    onClick={() => handleOnNavDisplay('hide')}
                />

                <Navigation
                    langSource={langSource}
                    className={onNavDisplay}
                    onDisplay={(showOrHideClass) => handleOnNavDisplay(showOrHideClass)}
                    setLanguage={(lang) => setLanguage(lang)}
                />

            </div>

        </header>
    )
}

export default Header;