import React from 'react';
import { Link } from "react-router-dom";

import iconArrowOrange from '../../../../img/icons/arrows/icon-arrow-orange.svg';
import iconArrowGreen from '../../../../img/icons/arrows/icon-arrow-green.svg';
import iconArrowBlue from '../../../../img/icons/arrows/icon-arrow-blue.svg';


import "./Anchor.scss";

const Anchor = ({ type, content, href, className, icon, children }) => {

    let src = '';

    const renderIcon = () => {
        if (icon === true) {
            if (className === 'orange') {
                src = iconArrowOrange;
            }

            if (className === 'green') {
                src = iconArrowGreen;
            }

            if (className === 'blue') {
                src = iconArrowBlue;
            }

            return (
                <img src={src} className="icon-arrow" alt={content} />
            )
        }
    }

    const renderAnchorType = () => {
        if (type === 'anchor') {
            return (
                <a href={href} className={`anchor ${className}`} target="_blank" rel="noreferrer">
                    {content}
                    {children}
                    {renderIcon()}
                </a>
            )
        }

        if (type === 'link') {
            return (
                <Link to={href} className={`anchor ${className}`}>
                    {content}
                    {children}
                    {renderIcon()}
                </Link>
            )
        }
    }


    return (
        <React.Fragment>
            {renderAnchorType()}
        </React.Fragment>
    )
}

export default Anchor;