import React from 'react';

import "./Entry.scss";

const Entry = ({ className, content }) => {

    return (
        <p className={`entry ${className}`}>{content}</p>
    )
}


export default Entry;