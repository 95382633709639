import React from 'react';

import Country from "./Country/Country";

import flagSpain from "../../../../img/about-us/flags/es.svg";
import flagItaly from "../../../../img/about-us/flags/it.svg";
import flagFrance from "../../../../img/about-us/flags/fr.svg";
import flagMexico from "../../../../img/about-us/flags/mx.svg";
import flagBrasil from "../../../../img/about-us/flags/br.svg";
import flagColombia from "../../../../img/about-us/flags/co.svg";
import flagChile from "../../../../img/about-us/flags/ch.svg";
import flagArgentina from "../../../../img/about-us/flags/ar.svg";
import flagPortugal from "../../../../img/about-us/flags/pt.svg";

import "./Timeline.scss";

const Timeline = ({ langSource }) => {

    const source = langSource.timeline;

    const countries = [
        {
            name: "spain",
            title: "MundoPsicologos.com",
            flag: flagSpain,
            year: "2008",
            href: "https://www.mundopsicologos.com/"
        },
        {
            name: "italy",
            title: "GuidaPsicologi.it",
            flag: flagItaly,
            href: "https://www.guidapsicologi.it/"
        },
        {
            name: "france",
            title: "Psychologue.net",
            flag: flagFrance,
            year: "2011",
            href: "https://www.psychologue.net/"
        },
        {
            name: "mexico",
            title: "Psico.mx",
            flag: flagMexico,
            href: "https://www.psico.mx/"
        },
        {
            name: "brasil",
            title: "MundoPsicologos.com",
            flag: flagBrasil,
            year: "2012",
            href: "https://br.mundopsicologos.com/"
        },
        {
            name: "colombia",
            title: "Psicologos.com.co",
            flag: flagColombia,
            year: "2015",
            href: "https://www.psicologos.com.co/"
        },
        {
            name: "chile",
            title: "PsicologosOnline.cl",
            flag: flagChile,
            href: "https://www.psicologosonline.cl/"
        },
        {
            name: "argentina",
            title: "MundoPsicologos.com.ar",
            flag: flagArgentina,
            year: "2017",
            href: "https://www.mundopsicologos.com.ar/"
        },
        {
            name: "portugal",
            title: "MundoPsicologos.pt",
            flag: flagPortugal,
            year: "2018",
            href: "https://www.mundopsicologos.pt/"
        }
    ]

    const renderCountries = () => {
       return countries.map((country, index) => {
            return (
                <Country
                    key={index}
                    year={country.year}
                    flag={country.flag}
                    title={country.title}
                    href={country.href}
                    name={source[country.name]}
                />
            )
        })
    }

    return (
        <section className="timeline">

            <div className="timeline-countries">

                <div className="timeline-countries__header">
                    <p>2007. Timeline</p>
                    <p>{source["intro"]}</p>
                </div>

                {renderCountries()}

                <div className="timeline-countries__ending">
                        <p>2020 ·
                            <a href="https://mundopsicologos.policourse.com/" target="_blank" rel="noreferrer">
                                {source["workshop-link"]}
                            </a>
                        </p>
                    <p>{source["end-link"]}</p>
                </div>

            </div>

        </section>
    )
}

export default Timeline;