import React from 'react';

import linkedinLogo from '../../../../../../img/rrss/linkedin-square.svg';
import iconEmail from '../../../../../../img/icons/icon-email.svg';

import "./NavigationFooter.scss";

const NavigationFooter = ({ langSource }) => {

    const source = langSource.footer;

    return (
        <div className="navigation-footer">
            <p className="navigation-footer__title">{source["rrss"]}</p>

            <div className="navigation-footer__contact">
                <a href="https://www.linkedin.com/company/the-mind-hub-company/" target="_blank" rel="noreferrer">
                    <img src={linkedinLogo} alt="The Mind Hub Linkedin" />
                </a>
                <div className="email">
                    <p>{source["contact"]}</p>

                    <div>
                        <img src={iconEmail} alt="info@themindhubcompany.com" />
                        <a href="mailto:info@themindhubcompany.com"
                           title="info@themindhubcompany.com">info@themindhubcompany.com</a>
                    </div>

                </div>
            </div>

            <p className="navigation-footer__copyright">{source["copyright"]}</p>
        </div>
    )
}

export default NavigationFooter;