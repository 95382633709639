import React from 'react';

import DepartmentCard from "./DepartmentCard/DepartmentCard";

import "./Careers.scss";



const Careers = ({ langSource }) => {


    const departments = [
        {
            name: "product-title",
            info: "product-info",
            className: "orange",
            href: "https://jobs.themindhubcompany.com/jobs",
            location: "Full remote"
        },
        {
            name: "tech-title",
            info: "tech-info",
            className: "red",
            href: "https://jobs.themindhubcompany.com/jobs",
            location: "Full remote"
        },
        {
            name: "new-business-title",
            info: "new-business-info",
            className: "green",
            href: "https://jobs.themindhubcompany.com/jobs",
            location: "Barcelona"
        }
    ]

    const renderDepartmentCards = () => {
        return departments.map((department, index) => {
            return (
                <li key={index}>
                    <DepartmentCard langSource={langSource}
                        className={department.className}
                        name={department.name}
                        info={department.info}
                        href={department.href}
                        location={department.location}
                    />
                </li>
            )

        });
    }

    return (
        <section className="careers">
            <ul>
               {renderDepartmentCards()}
            </ul>
        </section>
    )
}

export default Careers;
