import React from 'react';
import ReactMarkdown from "react-markdown";

import Entry from "../../components/atoms/Entry/Entry";
import Perks from "../../components/blocks/Perks/Perks";
import JoinUs from "../../components/blocks/JoinUs/JoinUs";

import Video from "./Video/Video";

import "./LifeAtTMH.scss";


const LifeAtTMH = ({ langSource }) => {

    const source = langSource.lifeattmh;

    return (
        <div className="tmh-life">

            <div className="tmh-life-header">
                <Entry content={source["entry"]} className="green" />
                <ReactMarkdown source={source["title"]} className="title"/>
                <p className="subtitle">{source["paragraph-1"]}</p>
            </div>

            <Video />

            <div className="tmh-life-wrapper">
                <p className="paragraph">{source["paragraph-2"]}</p>
            </div>

            <section className="tmh-life-gallery-1">
                <span />
                <picture />
            </section>


            <section className="tmh-life-wrapper">
                <p className="paragraph">{source["paragraph-3"]}</p>
            </section>


            <section className="tmh-life-wrapper">
                <p className="paragraph">{source["subtitle"]}</p>
                <ReactMarkdown source={source["paragraph-4"]} className="paragraph"/>
            </section>

            <Perks langSource={langSource} />

            <section className="tmh-life-gallery-2">
                <span />
                <picture />
            </section>

            <JoinUs className="dark" langSource={langSource} />
        </div>
    )
}

export default LifeAtTMH;